import router from './router'
import {setDocumentTitle} from '@/utils/domUtil'
import NProgress from 'nprogress'

NProgress.configure({showSpinner: false}) // NProgress Configuration

/**
 * no redirect whitelist
 * scanSupplierResult：扫描结果
 */
router.beforeEach((to, from, next) => {
    NProgress.start(); // start progress bar
    console.log(from)
    console.log(to)
    console.log(from.name, to.name)

    to.meta && (typeof to.meta.title !== 'undefined' && setDocumentTitle(`${to.meta.title} `));

    if (null === to.name) {
        next({path: '/home', query: {traceCode: to.path.replace("/", "")}});
    } else {
        next();
    }

    NProgress.done();

})

router.afterEach(() => {
    NProgress.done() // finish progress bar
})
