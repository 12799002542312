import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        meta: {
            title: '',
            icon: ''
        },
        redirect: 'home' //重定向
    },

    {
        path: '/home',
        name: 'home',
        meta: {
            title: '文昌鸡产品溯源',
            icon: ''
        },
        component: () => import('../views/home.vue')
    },
    {
        path: '/ct',
        name: 'commonTrace',
        meta: {
            title: '产品溯源',
            icon: ''
        },
        component: () => import('../views/commonTrace')
    },
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router
