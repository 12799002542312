import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// import Vant from 'vant';
import 'vant/lib/index.css';
import './utils/filter' // global filter
// import './core/use'
import './permission' // permission control
import Notify from "vant/es/notify";

import './assets/css/style.css'
import './assets/css/css.css'
import './assets/css/iconfont.css'
import './assets/css/animate.min.css'

// Vue.use(Vant);


Vue.config.productionTip = false

Vue.config.errorHandler = function (err, vm, info) {
  // handle error
  // `info` 是 Vue 特定的错误信息，比如错误所在的生命周期钩子
  // 只在 2.2.0+ 可用
  console.log("errorHandler");
  Notify({type: "danger", message: err.message});
  throw err;

}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
